<template>
  <v-container class="min-height-100">
    <v-row class="min-height-100 auth-page forgot-page ma-0">
      <!-- Left section that's hidden on phones -->
      <v-col class="d-none d-md-flex" md="6">
        <div
          class="logo-container d-flex align-center px-12 cursor-pointer"
          :class="host.theme.type === 'dark' ? 'black' : 'white'"
          @click="$router.push({ name: 'Home' })"
        >
          <img :src="host.images.logo" />
        </div>
      </v-col>

      <!-- Right section that does have a form -->
      <v-col class="min-height-100" cols="12" md="6">
        <div
          v-if="host.allowRegistration"
          class="text-right text-subtitle-2"
        >
          Don't have an account? &nbsp;
          <router-link class="text-decoration-none" :to="{ name: 'Register' }">
            Get Started
          </router-link>
        </div>

        <div class="form-container d-flex flex-column justify-center min-height-100">
          <div class="text-h5 font-weight-bold">
            Forgot Password?
          </div>
          <div class="text-subtitle-1 mt-3 mb-8 grey--text text--darken-2">
            Please provide us your email and we will send you the instructions on how you can retrieve your account.
          </div>

          <v-form @submit.prevent="handleSubmit">
            <v-text-field
              v-model="form.email"
              type="email"
              :error-messages="emailErrors"
              label="Email"
              outlined
              required
              @input="$v.form.email.$touch()"
              @blur="$v.form.email.$touch()"
            ></v-text-field>

            <div class="d-flex justify-space-between align-center">
              <router-link
                :to="{ name: 'Login' }"
                class="text-decoration-none text-body-2 font-weight-bold"
              >
                Login Instead
              </router-link>

              <v-btn
                :class="{ 'shadow--primary': !isLoading }"
                color="buttonPrimary"
                type="submit"
                large
                depressed
                :loading="isLoading"
                :disabled="isLoading"
              >
                Send Email
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, email } from "vuelidate/lib/validators"

const originalForm = () => ({
  email: ""
})

export default {
  name: "ForgotPassword",

  data: () => ({
    isLoading: false,
    form: originalForm()
  }),

  computed: {
    emailErrors () {
      // initiate the array of error messages
      const errors = []

      // if there has been no activity, show no error
      if (!this.$v.form.email.$dirty) return errors

      // else test it
      !this.$v.form.email.email && errors.push('Must be valid e-mail')
      !this.$v.form.email.required && errors.push('E-mail is required')

      // return the error messages
      return errors
    }
  },

  // Define the vuelidate validation rules
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },

  methods: {
    async handleSubmit() {
      // disable the button
      this.isLoading = true

      // Run validations
      await this.$v.$touch()

      // Show a loader while the request is being processed
      const loaderId = Symbol()
      await this.$store.dispatch("loaders/add", loaderId)

      try {
        await axios({
          url: "/api/password/forgot",
          method: "POST",
          data: this.form
        })

        // Show alert
        this.$store.dispatch("toasts/add", { text: "Please check your inbox for further instructions." })

        // Redirect to Home
        this.$router.replace({ name: "Login" })
      } catch (error) {
        logger({ type: "Forgot Error", error })

        // show the error message to the user
        this.$store.dispatch("toasts/add", { text: "An Error Occurred." })
      } finally {
        // remove the loader
        await this.$store.dispatch("loaders/remove", loaderId)

        // enable the button
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="stylus" src="@/stylus/auth.styl"></style>
